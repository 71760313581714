import './App.css';
import React from 'react';
import GlobalNavbar from "./components/GlobalNavbar/GlobalNavbar";
import { Route, Routes } from "react-router-dom";

import Home from "./pages/Home"
import Projects from './pages/Projects';

function App() {
  document.body.style.backgroundColor = "#f9f7ff"
  return (
    <div>
      <GlobalNavbar />
        <Routes>
            <Route path = "/" element={<Home />} />
            <Route path = "/projects" element={<Projects />} />
        </Routes>
    </div>
  );
}

export default App;
