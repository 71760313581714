import React from "react";
import { Container, Row, Col} from "react-bootstrap";
import gradHeadshot from "../images/gradHeadshot.jpg";
import headshot from "../images/headshot.jpg";
import softDevResume from "../documents/Astorga_Soft_Dev_Resume.pdf";
import ITResume from "../documents/Astorga_IT_Resume.pdf";


function Home() {
    return (
        <div className="main">
            <Container>
                <Row className="d-flex justify-content-center align-items-center text-center" style={{marginTop: '50px'}}>
                    <Col>
                        <img class="img-thumbnail" src={gradHeadshot} alt="headshot"/>
                    </Col>
                    <Col>
                        <div>
                            <h2> <u>About me</u> </h2>
                            <p style={{fontSize: 20,  marginTop: '30px'}}>
                                I am a recent college graduate from the University of Texas at Austin with a Bachelors of Science degree
                                in Computer Science. Just like with a puzzle, seeing the working, end result of any given project or task
                                gives me the upmost satisfaction. With a recent interest in the gaming industry, I'm excited to see
                                what I can create in the future!
                                </p>
                        </div>
                    </Col> 
                </Row>
                <Row className="d-flex justify-content-center align-items-center text-center" style={{marginTop: '50px', marginBottom: '50px'}}>
                    <Col>
                        <div>
                            <h2> <u>Contact Information</u> </h2>
                            <p style={{fontSize: 20, marginTop: '30px'}}>
                                Email: <a href="mailto:Cristian.astorga235@gmail.com">Cristian.astorga235@gmail.com</a> <br/>
                                Tel: (281)-235-9383 <br/>
                                LinkedIn: <a href="https://www.linkedin.com/in/cristianastorga/" target="_blank" rel="noreferrer"> cristianastorga</a>
                                </p>

                            <h2 style={{marginTop: '40px'}}> <u>Resumes</u> </h2>
                            <p style={{fontSize: 20, marginTop: '30px'}}>
                                <a href={softDevResume}> Software Developer resume</a> <br/>
                                <a href={ITResume}> IT resume</a>
                            </p>                            
                        </div>
                    </Col> 
                    <Col>
                        <img class="img-thumbnail" src={headshot} alt="other-headshot"/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Home;