// This is my list of prjects to be desplayed. The order should be from newest to oldest


// Imports all files (images) from the specifed 'images' folder
// Code logic from https://stackoverflow.com/questions/42118296/dynamically-import-images-from-a-directory-using-webpack

const importAll = require =>
    require.keys().reduce((acc, next) => {
        acc[next.replace("./", "")] = require(next);
        return acc;
        }, {});

const images = importAll(require.context('../images', false, /\.(png|jpe?g|svg|gif)$/));

export const project_list = [
    {
        title: "Application Tracker - React / Java / MySQL",
        date: "May 2024",
        description: "A web-based job application tracker with a team of 3 people created in 3 days",
        specfics: ["Project role: Programmer",
                   "Created and stored all data within a MySQL database",
                   "Encoded the front-end UI in React using the Bootstrap library for formatting",
                   "Encoded the backend software application in Java, using a 3-layer architecture",
                   "Tested the Service and Repository layer using JUnit testing",
                   "Testing made use of Mockito to simulate a Repository when testing the Service layer to ensure that production data was not used for code verification",
                   "Allows users to enter job application data through React forms which then populates the database using HTTP requests"],
        snippets: [{id: "1", title: "MySQL database diagram", img: images['app_track_database.png']},
                   {id: "2", title: "React: Use of the Fetch API to make HTTP requests upon an Interview's form submission", img: images['app_track_react.png']},
                   {id: "3", title: "UI Layer: Using Spring MVC to model HTTP requests", img: images['app_track_controller.png']},
                   {id: "4", title: "Service Layer: Adding an interview", img: images['app_track_service.png']},
                   {id: "5", title: "Service Testing: Utilizing Mockito to simulate a functional repository", img: images['app_track_test.png']},
                   {id: "6", title: "Data Layer: Adding an interview to the database using JdbcTemplate", img: images['app_track_repo.png']}],
        project_url: [],
        video_title: "Project Demo",
        video_url: "https://www.youtube.com/embed/rCe0T1JjjFw?si=yXeJOBAPdjTk7893",
    },
    {
        title: "Don't Wreck My House - Java",
        date: "April 2024",
        description: "An Airbnb-like application where the user can create reservations for guests at a specified location if the location is available for the designated period",
        specfics: ["Project role: Programmer",
                   "Stored the reservations for each location within its own .csv file",
                   "Would read and write to each file as reservations were created, edited, and deleted",
                   "Encoded the backend software application in Java using a 3-layer architecture",
                   "Tested the Service and Repository layer using JUnit testing",
                   "Testing made use of Repository test doubles to ensure production data was not used for code verification",
                   "Would display data and receive input via the console using the MVC design pattern"],
        snippets: [{id: "1", title: "Main application production files", img: images['DWMH_prod_files.png']},
                   {id: "2", title: "Application test files", img: images['DWMH_test_files.png']},
                   {id: "3", title: "Use of exception handling to gracfully exit with a fatal error", img: images['DWMH_excep.png']},
                   {id: "4", title: "Reservation class for future reference", img: images['DWMH_Reser.png']},
                   {id: "5", title: "UI Layer: Adding a reservation", img: images['DWMH_ui_add.png']},
                   {id: "6", title: "Service Layer: Adding a reservation", img: images['DWMH_serv_add.png']},
                   {id: "7", title: "Service Layer: Input verification to ensure valid Reservation data", img: images['DWMH_ver.png']},
                   {id: "8", title: "Data Layer: Adding a reservation", img: images['DWMH_data_add.png']},
                   {id: "9", title: "Data Layer: Writing to a .csv file", img: images['DWMH_writing.png']}],
        project_url: [],
        video_title: "Project Demo",
        video_url: "https://www.youtube.com/embed/EoZWMHBO1WY?si=DE0O6_NFsV0UCjaK",
    },
    {
        title: "Personal Site - React",
        date: "September 2023",
        description: "A personal site used to showcase my goals and recent projects using the React-Bootstrap library",
        specfics: ["Project role: Programmer, Designer",
                   "Made use of Cards to dynamically build out the project page based off of an imported array of projects",
                   "Used AWS to host the site by connecting this project's gitlab repository"],
        snippets: [{id: "1", title: "Code for the project page", img: images['Site_proj_page.png']},
                   {id: "2", title: "Snippet of the ProjectCard code", img: images['Site_proj_card.png']},
                   {id: "3", title: "Snippet of the array of projects", img: images['Site_proj_data.png']}],
        project_url: [{link: "/", text: "Home page", newTab: false}],
        video_title: "",
        video_url: "",
    },
    {
        title: "Lab Echo - Blueprint / Unreal Engine 5.1",
        date: "Spring 2023",
        description: "A 3D Horror-esque Narrative Puzzle game made in Unreal Engine 5.1 with a group of 7 people",
        specfics: ["Project roles: Programmer, Scrum Master",
                   "Created an in-game tutorial that activates after certain player interactions in Blueprint for both VR and Desktop",
                   "Designed a lockable double door that can be unlocked in different ways in Blueprint for both VR and Desktop",
                   "Encoded the logic to unlock the doors upon the door puzzle's completion",
                   "Created the UI for the door puzzle and ensured it worked properly in VR and Desktop",
                   "Designed and created the end sequence of the game and its assets"],
        snippets: [{id: "1", title: "Tutorial activation logic", img: images['LE_tutorial.png']},
                   {id: "2", title: "Lockable double door", img: images['LE_door.png']},
                   {id: "3", title: "Use of delegates to unlock doors (keypad called)", img: images['LE_door_unlock.png']},
                   {id: "4", title: "Door puzzle UI", img: images['LE_puzz_ui.png']}],
        project_url: [{link: "https://spooncats.itch.io/lab-echo", text: "Project page", newTab: true}],
        video_title: "Gameplay Trailer",
        video_url: "https://www.youtube.com/embed/fK8v4-n66fE?si=6LQglODRjDI5CRer",
    },
    {
        title: "Tales of Kogi - C# / Unity",
        date: "Fall 2022",
        description: "A 2D platformer game made with the Unity engine with a group of 7 people",
        specfics: ["Project role: Programmer",
                   "Expanded the player controller to have smoother movements and animated it using Unity’s animation system",
                   "Designed and implemented the different enemy AIs, animated them using Unity’s animation system, and encoded the player-enemy interactions",
                   "Implemented the end-of-level win condition used to progress to the next area",
                   "Designed and implemented the pause menu and game over screens",
                   "Implemented the Camera controller to always be slightly in front of Kogi so the player can see all front facing platforms"],
        snippets: [{id: "1", title: "Player horizontal movement with slope adjustments", img: images['ToK_movem.png']},
                   {id: "2", title: "Enemy stunning due to player interaction", img: images['ToK_en_inter.png']},
                   {id: "3", title: "End-of-level / Door opening interaction", img: images['ToK_door.png']},
                   {id: "4", title: "In-game pause menu", img: images['ToK_pause.png']},
                   {id: "5", title: "Camera controller", img: images['ToK_camera.png']}],
        project_url: [{link: "https://omelettefried.itch.io/tales-of-kogi", text: "Project page", newTab: true}],
        video_title: "Gameplay Trailer",
        video_url: "https://www.youtube.com/embed/IbWMWOcVVto?si=dUlQ3vsrX33CUyfO",
    },
    {
        title: "Tiled Q-Bone Game - C++ / Godot",
        date: "Fall 2022",
        description: "A 3D procedurally generated dungeon crawler game made in Godot with a group of 3 people",
        specfics: ["Project role: Programmer",
                   "Created a class that dynamically adds a navigation grid so that our enemy AI could navigate around the map using AStar pathing",
                   "Spawned in a random number of enemies per room depending on an editor exposed max variable"],
        snippets: [{id: "1", title: "AStar grid initialization", img: images['QB_grid_init.png']},
                   {id: "2", title: "AStar grid points", img: images['QB_astar_pts.png']},
                   {id: "3", title: "Random enemy spawns", img: images['QB_spawn.png']},
                   {id: "4", title: "Enemy navigation in action", img: images['QB_gif.gif']}],
        project_url: [],
        video_title: "",
        video_url: "",
    },
    {
        title: "Darwin - C++",
        date: "Spring 2022",
        description: "An animal simulator where the world is represented by a 2D grid populated by creatures in C++",
        specfics: ["Project role: Programmer (Pair-Programmed)",
                   "Designed class interfaces to allow users to create creatures and assign them a list of instructions",
                   "Used OOP principles to divide the code into separate classes and represented the design in a UML diagram"],
        snippets: [{id: "1", title: "Project UML Diagram", img: images['darwin_uml.png']},
                   {id: "2", title: "Single animal step simulation", img: images['darwin_sim.png']}],
        project_url: [],
        video_title: "",
        video_url: "",
    },
    {
        title: "My Word Count - C",
        date: "Spring 2021",
        description: "A text file word counter that emulates the Linux word count command in C",
        specfics: ["Project role: Programmer",
                   "Program returns the newline, word, and byte counts for each passed in file",
                   "Accepts multiple file inputs in the command line and returns the count totals for each file"],
        snippets: [{id: "1", title: "Program usage description", img: images['mywc_des.png']},
                   {id: "2", title: "Input parsing logic", img: images['mywc_input.png']}],
        project_url: [],
        video_title: "",
        video_url: "",
    },
    {
        title: "Malloc Lab - C",
        date: "Fall 2020",
        description: "A basic C data allocator that mimics the malloc, remalloc, and free functions",
        specfics: ["Project role: Programmer",
                   "Makes use of an explicit free list to keep track of available free blocks of memory",
                   "Breaks up and recombines memory blocks to allow for the most efficient memory usage"],
        snippets: [{id: "1", title: "Malloc logic", img: images['mm_malloc.png']},
                   {id: "2", title: "Free logic", img: images['mm_free.png']},
                   {id: "3", title: "Coalesce logic", img: images['mm_coalesce.png']}],
        project_url: [],
        video_title: "",
        video_url: "",
    },
]