import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { LinkContainer } from 'react-router-bootstrap'
//import personalLogo from '../../images/mylogo.svg';

// Used https://react-bootstrap.github.io/docs/components/navbar/

const GlobalNavbar = () => { 
  return (
    <Navbar sticky="top" variant="dark" style={{backgroundColor: "#700da6"}}>
      <Container>
        {/* <Navbar.Brand href="/">
          <img src={personalLogo} 
            height="25"
            alt="logo"/>
            &nbsp;
            (bar name)
        </Navbar.Brand> */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/">
                <Nav.Link>Home</Nav.Link>
            </LinkContainer>

            <LinkContainer to="/projects">
                <Nav.Link>Projects</Nav.Link>
            </LinkContainer>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default GlobalNavbar;