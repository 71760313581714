import React from 'react';
import ProjectCard from '../components/Cards/ProjectCard';
import { project_list } from '../data/ProjectData';
import Container from 'react-bootstrap/Container';


function Projects() {
  return (
    <Container>
      <Container>
        {project_list.map((data) => {
          return (
            <ProjectCard
                title = {data.title}
                date = {data.date}
                description = {data.description}
                specfics = {data.specfics}
                snippets = {data.snippets}
                project_url = {data.project_url}
                video_title = {data.video_title}
                video_url = {data.video_url}
            ></ProjectCard>
          )
        })}
      </Container>

      <p style={{textAlign: "center"}}> <span style={{color:"gray"}}>Page 1 of 1</span></p>

    </Container>
  )
};

export default Projects;