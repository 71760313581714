import { React, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Collapse from 'react-bootstrap/Collapse';
import Image from 'react-bootstrap/Image';
import { ListGroup, ListGroupItem, Row } from 'react-bootstrap';

// Multiple Collapsable state logic influenced by https://stackoverflow.com/questions/71599893/bootstrap-react-collapse-multi

function ProjectCard(props) {
    const title = props.title
    const date = props.date
    const description = props.description
    const specfics = props.specfics
    const snippets = props.snippets
    const project_url = props.project_url
    const video_title = props.video_title
    const video_url = props.video_url

    // Multiple Collapsable state logic
    const initialState = snippets
        .filter((visual) => visual)
        .reduce((a, v) => ({ ...a, [v.id]: false }), {});

    const [openState, setOpenState] = useState(initialState);
    const handleClick = (photoId) =>
        setOpenState((prev) => {
        return { ...prev, [photoId]: !prev[photoId] };
    });

    return (
        <Container>
            <Card className="mt-5 mb-4">
                <Card.Header>
                    <Row>
                        <h2 className='col'> {title} </h2>
                        <h2 className='col-auto'> {date} </h2>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Card.Text> 
                        <h3>Description</h3> {description} <br/>
                        {/* Prints out the bullet points in the project description */}
                        <ul>
                            {specfics.map(desc => {
                                return <li> {desc} </li>
                            })}
                        </ul>

                        {/* Opens the project's provided webpage in another tab if desired */
                          project_url.lenght !== 0 &&
                            <div>
                                {project_url.map((data) => {
                                    return (
                                        <div>
                                            {data.newTab // Determines if we want to open the link in a new tab or not
                                                ? <a href={data.link} target="_blank" rel="noreferrer"> {data.text} </a>
                                                : <a href={data.link}> {data.text} </a>
                                            }
                                        </div>
                                    );
                                })}
                            </div>
                        }
                    </Card.Text>
                    
                    {/* Displays the project's code snippets if provided */}
                    {snippets.length !== 0 &&
                        <div className="mb-4">
                            <h3>Code Snippets</h3>
                            <ListGroup>
                                {snippets.map((photo) => {
                                    return (
                                        <ListGroupItem
                                            variant="secondary"
                                            key={photo.id}
                                            onClick={() => handleClick(photo.id)}
                                        >
                                            {photo.title}
                                            <Collapse in={openState[photo.id]}>
                                                <div className="mt-2">
                                                    <Image src={photo.img} alt="screenshot" fluid />
                                                </div>
                                            </Collapse>
                                        </ListGroupItem>
                                    );
                                })}
                            </ListGroup>
                        </div>
                    }

                    {/* Displays the project's video if one is provided */}
                    {video_url !== "" &&
                        <div>
                            <h3> { (video_title !== "") ? video_title : "Video" } </h3>
                            <iframe width="700" height="394" src={video_url} title="YouTube video player" frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; 
                                picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    }
                </Card.Body>
            </Card>
        </Container>
    );
}

export default ProjectCard